const isValidDomain = require("is-valid-domain");
const currentTheme = process.env.GATSBY_THEME;

if (!currentTheme) throw new Error("The current theme folder is not defined.");

module.exports.currentTheme = currentTheme;

module.exports.getThemeData = () => {
  const { theme } = require(`./${currentTheme}/theme.js`);

  if (!theme.id) {
    throw new Error("The theme is missing the siteId required to get posts from the api.");
  }
  if (!isValidDomain(theme.url)) {
    throw new Error("Please use a domain/url without https:// or a trailing /");
  }

  if (!theme.googleAnalyticsId) {
    throw new Error("this theme is missing a googleAnalyticsId.");
  }

  if (!theme.design.fonts) {
    throw new Error("fonts image required");
  }
  if (!theme.author.name) {
    throw new Error("Author.name is required");
  }
  if (!theme.author.bio) {
    throw new Error("Author.bio is required. It can contain html");
  }
  if (!theme.author.shortBio) {
    throw new Error("Author.shortBio is required. It should be short and just contain text.");
  }

  const urlWithProtocol = `https://${theme.url}`;
  const sitemapUrl = `${urlWithProtocol}/sitemap.xml`;

  return { ...theme, urlWithProtocol, sitemapUrl };
};
