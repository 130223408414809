/* eslint-disable */
module.exports.theme = {
  id: "5e01583d7462de0008096bc8",
  url: "thetopmusicalinstruments.com",
  googleAnalyticsId: "UA-155173703-2",
  title: "Top Musical Instruments",
  description:
    "Need the perfect piano for your new band? Trying to learn Mozart? Or did your kid just join band and you need a new instrument? Whatever it is, we have the tips for you!",
  design: {
    fonts: ["Montserrat"],
  },
  author: {
    name: "Top Music Man",
    shortBio: "Top Music Man is a professional music teacher and has a bachelor's degree in music.",
    bio: `<p>Top Music Man has played instruments his whole life and taught students of all ages. He understands that beginners need something to get started with -- your first car doesn't need to be a ferrari, and you don't need to spend a ton of money on your first instrument!</p>
    <p>Once you have leveled up and are becoming more serious about musical studies then you will most likely need an upgraded musical instrument as well! The best choice for you will be unique to you depending on budget, musical preferences, and more.  The Top Musical Instruments was started with the goal of providing best-of lists for every budget and skill level.</p>`,
  },
  meta: {
    description:
      "Best musical instruments for every skill level, from beginners to intermediate and advanced players. Read our blog to learn more!",
  },
  home: {
    h1: "Everything you need to know about buying instruments online",
    intro: "Let's get started.",
  },
};
