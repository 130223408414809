/* eslint-disable */
module.exports.theme = {
  id: "5e1e2e62fceb5b00085e2dd7",
  url: "justbizware.com",
  googleAnalyticsId: "UA-155173703-3",
  title: "Just Bizware",
  description: "JustBizware is business plus software.!",
  design: {
    fonts: ["Alegreya"],
  },
  author: {
    name: "Tyler Zey",
    shortBio: "Tyler Zey is a serial entrepreneur who has experimented with a lot of business software.",
    bio: `<p>Tyler Zey founded Easy Agent Pro in 2012 which was acquired in 2018 after exponential growth.  He started Zey Ventures in 2019, a web syndicate, and created the <a href="/">Just Bizware blog</a> as a place to put his honest reviews of business software and what software is a must for any small business owner.</p>`,
  },
  meta: {
    description: "Small business software reviews by a serial entrepreneur.",
  },
  home: {
    h1: "Small Business Software Reviews",
    intro: "We try it so you don't have to.",
  },
};
