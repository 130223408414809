/* eslint-disable */
module.exports.theme = {
  id: "5e403e92c49471000841e778",
  url: "familypetlover.com",
  googleAnalyticsId: "UA-155173703-5",
  title: "Family Pet Lover",
  description: "Family pet lover reviews all the best toys, treats, and beds for your furry friend.",
  design: {
    fonts: ["Nunito"],
  },
  author: {
    name: "Sophia Zey",
    shortBio: "Sophia Zey has two large rescue dogs.",
    bio: `<p>Sophia Zey grew up always wanting a pet and now lives happily with her two large rescue dogs and husband, <a href="https://tylerzey.com" title="Tyler Zey" target="_blank">Tyler Zey</a> in Wisconsin.</p>
    <p>With so much overwhelming pet information out there online including many fads without scientific backing, her goal for <a href="/">familypetlover.com</a> is to provide resources for those who want something that works for their family pet, without all the pseudoscience!  </p>`,
  },
  meta: {
    description: "Family Pet Lover aims to provide resources and quality content for pet owners on a budget.",
  },
  home: {
    h1: "Love your pet and love your savings.",
    intro: "Family Pet Lover aims to provide resources and quality content for pet owners on a budget.",
  },
};
