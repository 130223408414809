import React, { useContext } from "react";
/**
 * @sophia -
 *
 * This file is essentially consuming our active theme from /components/Layouts.js
 *
 * It then exposes the theme anywhere we pull in useTheme.
 *
 * So, in any component you can just say
 *
 * const theme = useTheme();
 *
 * It's called a React context. They make it easy for sharing/consuming data in a sane way.
 */
export const ThemeContext = React.createContext({});

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ThemeContext.Provider;
