/* eslint-disable */
module.exports.theme = {
  id: "5e171cd4f0a96a00077dd04d",
  url: "mrtoolpro.com",
  googleAnalyticsId: "UA-155173703-4",
  title: "Mr. Tool Pro",
  description:
    "Mr. Tool Pro examines all the options out there for your power tool needs and provides the top recommendations for you.",
  design: {
    fonts: ["Coda"],
    fontWeights: ["400", "800"],
  },
  author: {
    name: "Mr. ToolPro",
    shortBio:
      "Mr. ToolPro has been a tinkerer his whole life. He really enjoys fixing up things around the house. Tools are his passion. He enjoys comparing them and finding the best tool for the job.",
    bio: `<p>Mr. ToolPro starting working for ZeyVentures to help share his knowledge of tools. <a href="https://zeyventures.com">ZeyVentures</a> approached him to share his insights about everything from the best power tools to the top outdoor lawn & garden equipment.</p>
	 <p>He enjoys fixing up various things around the house. More than that, he knows what makes the best choice tool for the job.</p>
	 <p>He believes that essentially every category of tool basically has 2-3 things you need to consider before purchasing it.</p>`,
  },
  meta: {
    description:
      "MrToolPro provides a list of the top tool for the job. Whether you are looking for power drills or snow blowers, we have everything broken down for you.",
  },
  home: {
    h1: "Find the top tool for any job.",
    intro:
      "MrToolPro provides a list of the top tool for the job. Whether you are looking for power drills or snow blowers, we have everything broken down for you.",
  },
};
