/* eslint-disable */
module.exports.theme = {
  id: "5e0a7f6649e81400073a765e",
  url: "justtreesonline.com",
  googleAnalyticsId: "UA-155173703-1",
  title: "Just Trees Online",
  description:
    "Just Trees Online is here to provide you with the best tree tips whether you're new to planting or a seasoned pro!",
  design: {
    fonts: ["Ubuntu"],
    fontWeights: ["300", "400", "500", "700"],
  },
  author: {
    name: "Tyler Zey",
    shortBio: "Tyler loves the challenge of finding the right trees for any piece of land.",
    bio: `<p>Tyler has enjoyed the outdoors his entire life. He lives in Wisconsin surrounded by the great outdoors. He has planted over 200 trees on his property. His favorite tree is the well placed Blaze Red Maple tree. </p>
	 <p>He started <a href="/">Just Trees Online</a> to help others find the best trees for their yard. In his spare time, he enjoys gardening, piano, and generally being outdoors.</p>`,
  },
  meta: {
    description:
      "Buying trees online requires research -- which we have done for you! Read our guides to help you find the perfect trees online.",
  },
  home: {
    h1: "Everything you need to know about buying trees online",
    intro:
      "If you're looking to buy trees online, our articles will help save you time and money. Whether you're looking for fast growing trees, privacy trees, ornamental trees, or hardy trees, we have articles on all of them and the zones that they thrive in.",
  },
};
