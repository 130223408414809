import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "./src/components/ThemeContext";
import { getThemeData, currentTheme } from "./src/sites/getThemeData";

const theme = getThemeData();

export default function Wrapper({ element }) {
  return <ThemeProvider value={{ theme, currentTheme }}>{element}</ThemeProvider>;
}
Wrapper.propTypes = {
  element: PropTypes.any.isRequired,
};
