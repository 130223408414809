import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
export const onServiceWorkerUpdateReady = () => window.location.reload(true);
